<template>
  <div class="tariffs-page">
    <top-bar back backroute="profile" :title="$app.secure.clean($n.t('12.1'))" />
    <div class="container">
      <div class="wrap">
        <div v-for="item in tariffs" :key="item.id" class="item">
          <div class="title">{{ item.name }}</div>
          <div class="desc">{{ item.description }}</div>
          <n-button wide color="yellow"><t name="12.2" /></n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageIndex',
  data() {
    return {
      tariffs: [],
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.tariffs.get().then((response) => {
        this.tariffs = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.tariffs-page {
  .wrap {
    .item {
      border: 1px solid #D9D9D9;
      border-radius: 12px;
      padding: 16px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }

      .title{
        font-weight: 500;
        font-size: 20px;
        color: #102E4F;
        margin-bottom: 4px;
      }

      .desc{
        font-weight: 400;
        font-size: 16px;
        color: #102E4F;
        margin-bottom: 16px;
      }

      .active-tariff{
        font-weight: 400;
        font-size: 16px;
        color: #52C41A;
        border: 1px solid #52C41A;
        border-radius: 6px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        .img{
          width: 12px;
          height: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;

          .n-icon{
            transform: translate(0, -2px);
          }
        }
      }
    }
  }
}
</style>
